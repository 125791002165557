.accordion {
    .accordion-title {
        display: flex;
        justify-content: space-between;
         align-items: center;
    }

  .btn-accordion-expand {
    padding: 1rem;
    .accordion-icon {
      display: block;
      height: 1.5rem;
      width: 1.5rem;
      background: url("../../assets/images/arrow-up.svg") center no-repeat;
      background-color: transparent;
      transform: rotate(180deg);
      transition: 0.25s linear;

      &.expanded {
        transform: rotate(0);
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-medium) {
  .accordion {
    margin-bottom: 2rem;
  }
}