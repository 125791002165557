@import "../../../../assets/styles/variables";

.price {
  display: flex;
  font-size: $l-font-size;

  &.small {
    font-size: $base-m-font-size;
  }

  .amount {
    color: $primary-color;
    line-height: 0.9;
  }

  .currency {
    margin-left: 0.5rem;
    color: $black-color;
    line-height: 0.9;
  }
}

@media only screen and (max-width: $breakpoint-medium) {
  .price {
    font-size: $ml-font-size;

    &.small {
      font-size: $base-s-font-size;
    }
  }
}