@import "variables";

.inquiry-form-host {
  background: url('../images/inquiry_bg_lines_left.webp'), url("../images/inquiry_bg_lines_right.webp");
  background-repeat: no-repeat, no-repeat;
  background-position: top left, bottom right;
  background-size: 40%;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 2rem 6rem;

  .logo-container {
    min-height: 6.8rem;
    width: 34rem;
    display: block;
    background: url('../images/inquiry_logo.webp') center no-repeat;
    background-size: contain;
    margin: 0 0 5rem 0;
  }

  .title {
    text-shadow: 0.41rem 0.44rem 0.5rem $text-shadow-color;
    font-family: Montserrat, sans-serif;
    font-size: $xxxxxl-font-size;
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: 0.125rem;
    text-align: center;
    color: $black-color;
  }

  .inquiry-form-card {
    background-color: $white-color;
    border-radius: 0.6rem;
    box-shadow: 0 0 1.5rem 0 rgba($black-color, 0.1);
    padding: 6rem 5rem 5.5rem;
    width: 100%;
    max-width: 80rem;

    &.inquiry-data {
      position: relative;

      &:before {
        content: "";
        display: block;
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../images/inquiry_data_image.webp');
        top: -13rem;
        right: -22rem;
        width: 34.6rem;
        height: 31.9rem;
      }
    }

    &.inquiry-variant {
      position: relative;

      &::before, &::after {
        content: "";
        display: block;
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
      }

      &::before {
        background-image: url('../images/inquiry_variant_top.webp');
        top: -15rem;
        left: 95%;
        width: 41rem;
        height: 31.8rem;
        z-index: 999;
      }

      &::after {
        background-image: url('../images/inquiry_variant_bottom.webp');
        bottom: 12rem;
        left: -24rem;
        width: 25.5rem;
        height: 26.2rem;
        z-index: -1;
      }

      .variables-table-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 2rem;

        .label {
          &.small {
            font-size: $small-font-size;
          }

          div {
            &:first-child {
              margin-bottom: 0.5rem;
            }

            span {
              font-size: $base-m-font-size;
            }
          }

          span {
            color: $primary-color;
          }
        }
      }
    }

    &.policyholder-card {
      position: relative;

      &:after {
        content: "";
        display: block;
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../images/inquiry_policyholder.webp');
        bottom: -6rem;
        left: -25rem;
        width: 29.9rem;
        height: 30.6rem;
      }
    }

    &.summary-card {
      position: relative;

      &:before {
        content: "";
        display: block;
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../images/inquiry_summary.webp');
        top: -12rem;
        left: 90%;
        width: 26.4rem;
        height: 27.1rem;
        z-index: -1;
      }

      .inquiry-summary {
        position: relative;

        .inquiry-summary-row {
          display: flex;
          gap: 0.9rem;
          margin-bottom: 1rem;

          .inquiry-summary-item {
            padding: 2rem;
            background-color: $footer-background;
            display: flex;
            flex-direction: column;
            flex: 1;
            min-width: 0;
            overflow-wrap: break-word;
            overflow: hidden;
            white-space: normal;

            .inquiry-summary-label {
              margin-bottom: 1rem;
              font-family: $montserrat-font-family;
              font-size: $base-s-font-size;
              font-weight: bold;
              letter-spacing: 0.09rem;
              color: $black-color;
            }

            .inquiry-summary-value {
              font-family: $montserrat-font-family;
              font-size: $base-s-font-size;
              font-weight: 500;
              letter-spacing: 0.09rem;
              color: rgba($black-color, 0.6);
            }
          }
        }
      }

      .consents-container {
        display: flex;
        flex-direction: column;
        margin: 4rem 0 2rem;

        .consent-wrapper {
          margin-left: 4rem;
        }

        .consent {
          display: flex;
          align-items: flex-start;
          margin-bottom: 3rem;

          &.required {
              .consent-label {
                position: relative;
                padding-left: 3.25rem;
                &:after {
                  content: "*";
                  position: absolute;
                  top: 0.25rem;
                  left: 1.75rem;
                  color: $primary-color;
                }
              }
          }

          .consent-label {
            padding-top: 0.25rem;
            padding-left: 1.75rem;
            line-height: 1.75;
            font-weight: 500;
            font-size: 1.3rem;
            color: $black-color;

            a {
              color: $primary-color;
              text-decoration: none;
              font-size: $base-font-size;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          .checkbox-container {
            .checkbox-wrapper {
              margin: 0;
              padding: 0;
              height: 2.4rem;
              width: 2.4rem;

              .checkbox-checkmark {
                top: 0.1rem;
                height: 2rem;
                width: 2rem;
              }

              .checkbox-label {
                display: none;
              }
            }
          }
        }
      }
    }

    &.success-card {
      margin: 10rem 0 6rem;
      position: relative;
      width: 80%;

      &:after {
        content: "";
        display: block;
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../images/inquiry_success.webp');
        bottom: -12rem;
        left: 80%;
        width: 33.5rem;
        height: 25.7rem;
      }
    }

    .inquiry-step-title {
      text-shadow: 0.41rem 0.44rem 0.5rem $text-shadow-color;
      font-family: $montserrat-font-family;
      font-size: $x-font-size;
      font-weight: 500;
      line-height: 2.2;
      letter-spacing: 0.063rem;
      color: $black-color;
      margin-bottom: 3rem;
    }

    .button-container {
      margin-top: 3rem;
      display: flex;
      justify-content: space-between;
    }

    .payment-summary-card {
      .payment-summary {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;

        .payment-summary-row {
          display: flex;
          justify-content: space-between;
          padding: 2.4rem;
          background-color: $footer-background;
          font-family: $montserrat-font-family;
          font-size: $base-m-font-size;
          font-weight: 600;
          letter-spacing: 0.08rem;
          color: $black-color;

          .variant-name-container {
            display: flex;
            align-items: flex-end;
            text-transform: uppercase;

            .variant-name-clauses {
              padding-left: 0.5rem;
              font-size: $base-font-size;
              text-transform: lowercase;
              line-height: 1.6;
            }
          }

          .variant-price-container {
            display: flex;
            align-items: flex-end;
            text-align: right;
            line-height: 0.9;

            .variant-price {
              font-size: $l-font-size;
            }
            .variant-duration {
              margin-left: 0.5rem;
              font-size: $xs-font-size;
            }
          }
        }
      }
    }

    .success-details {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-family: $montserrat-font-family;
      color: $black-color;

      .success-title {
        text-shadow: 0.41rem 0.44rem 0.5rem $text-shadow-color;
        font-size: $x-font-size;
        font-weight: 500;
        line-height: 2.2;
        letter-spacing: 0.063rem;
        text-align: center;
        margin-bottom: 3.7rem;
      }

      .success-description {
        font-size: $base-s-font-size;
        line-height: 1.67;
        max-width: 46rem;
        text-align: center;
      }

      .social-icons-wrapper {
        margin: 2rem 0;
        display: flex;

        .social-link {
          .icon {
            display: flex;
            width: 1.6rem;
            height: 1.6rem;
            justify-content: center;
            align-items: center;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            &.facebook {
              background-image: url("../images/footer_fb_icon.webp");
            }

            &.instagram {
              background-image: url("../images/footer_ig_icon.webp");
            }

            &.linkedin {
              background-image: url("../images/footer_linkedin_icon.webp");
            }
          }

          &:hover {
            opacity: 0.4;
          }

          &:nth-child(2) {
            margin: 0 7.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-medium) {
  .inquiry-form-host {
    padding: 1.6rem 0.8rem 4rem;

    .logo-container {
      margin: 3rem auto;
      width: 25rem;
      min-height: 5rem;
    }

    .title {
      text-shadow: 0 0.6rem 0.5rem $text-shadow-color;
      font-size: $xxl-font-size;
    }

    .inquiry-form-card {
      padding: 3rem 1.2rem;

      &::after, &::before {
        content: unset!important;
      }

      &.success-card {
        margin: 6rem auto;
        width: unset;
      }

      .inquiry-step-title {
        text-shadow: 0 .6rem 0.5rem $text-shadow-color;
        line-height: 1.2;
        margin-bottom: 2.9rem;
      }

      .payment-summary-card {
        position: fixed;
        padding: 2rem;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9999;
        background: $white-color;
        box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.1);

        .payment-summary {
          padding: 0;
          background: transparent;

          .payment-summary-row {
            .variant-name-container {
              flex-direction: column;
              align-items: flex-start;
            }

            .variant-price-container {
              flex-direction: column;
            }
          }
        }

        .button-container {
          margin-top: 1rem;
        }
      }

      .phone-control {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-small) {
  .inquiry-form-host {
    display: block;
    .inquiry-form-card {
      width: unset;
      .inquiry-summary {
        .inquiry-summary-row {
          flex-direction: column;

          .inquiry-summary-item {
            padding: 2rem;
          }
        }
      }
    }
  }
}