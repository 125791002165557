@import "../../../assets/styles/variables";

.variantsTable {
  width: 100%;
  margin-bottom: 2rem;
  border-collapse: separate;
  border-spacing: 0;

  tbody {
    tr {
      &.rowTableHeader {
        font-size: $l-font-size;
        line-height: 1.25;
        letter-spacing: 0.10rem;

        td {
          padding: 2rem 1rem 1.2rem;
          border: 0.3rem solid transparent;
          border-bottom: none;
          border-top-right-radius: 0.6rem;
          border-top-left-radius: 0.6rem;

          &.selected {
            border-color: $primary-color;
          }

          .additionalClausesLabel {
            display: block;
            width: 100%;
            font-size: $base-font-size;
            text-align: left;
          }
        }
      }

      &.rowMainContent {
        background-color: $inquiry-plan-table-header-background-color;
        cursor: pointer;

        &.expanded {
          td {
            &.cellExpandButton {
              .btnExpand {
                .btnExpandIcon {
                  transform: rotate(0);
                }
              }
            }
          }
        }

        td {
          &.cellContent {
            padding: 1rem;
          }

          &.cellExpandButton {
            padding: 0;

            .btnExpand {
              padding: 1rem;
              background: none;

              .btnExpandIcon {
                display: block;
                height: 1.5rem;
                width: 1.5rem;
                background: url("../../../assets/images/arrow-up-active.svg") center no-repeat;
                background-color: transparent;
                transform: rotate(180deg);
                transition: 0.25s linear;
              }
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }

      &.rowExtraContent {
        background-color: $footer-background;

        td {
          &.cellContent {
            padding: 1rem;

            .extraContentLabel {
              width: 100%;
              display: block;
              text-align: left;
              font-size: $small-font-size;
              font-weight: 400;
              letter-spacing: 0.06rem;
            }

            .extraClauseLabel {
              width: 100%;
              display: block;
              text-align: left;
              font-size: $base-font-size;
              font-weight: 400;
              letter-spacing: 0.06rem;
            }
          }

          .details {
            font-family: $montserrat-font-family;
            font-size: $xxs-font-size;
            font-weight: 600;
            line-height: 1.4;
            color: $black-color;
          }
        }
      }

      &.rowButtons {
        .cellButton {
          padding: 0 1rem 1rem;
          border-bottom: 3px solid transparent;
          border-bottom-left-radius: 0.6rem;
          border-bottom-right-radius: 0.6rem;

          &.selected {
            border-bottom-color: $primary-color;

            .btnSelectVariant {
              background-color: $button-selected-color;

              .btnIcon {
                display: block;
              }
            }
          }

          .btnSelectVariant {
            width: 100%;
            min-width: 13rem;
            padding: 1rem 0;
            background: $primary-color;
            color: $white-color;
            letter-spacing: 0.04rem;

            &:after {
              content: none;
            }

            .btnIcon {
              display: none;
              margin-left: 1rem;
              height: 1rem;
              width: 1.5rem;
              background: url("../../../assets/images/checkmark_white.svg") center no-repeat;
            }
          }
        }
      }

      &.rowSummary {
        td {
          border-bottom: 0.3rem solid transparent;
          border-bottom-left-radius: 0.6rem;
          border-bottom-right-radius: 0.6rem;

          &.selected {
            border-bottom-color: $primary-color;
          }

          .cellSummary {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 1rem 0;
            font-size: $ml-font-size;
            font-weight: 600;

            .priceContainer {
              display: flex;
              align-items: center;
              gap: 0.5rem;
            }
          }
        }
      }

      &.rowMainContentSpacer {
        td {
          padding: 0.5rem;
        }
      }

      &.rowExtraContentSpacer {
        td {
          padding: 0.25rem;
        }
      }

      &.rowTableSpacer {
        td {
          padding: 1rem;

          &.selected {
            border-left-color: $white-color;
            border-right-color: $white-color;
          }
        }
      }

      td {
        box-sizing: border-box;
        border-left: 0.3rem solid transparent;
        border-right: 0.3rem solid transparent;
        vertical-align: middle;
        font-family: $montserrat-font-family;
        font-size: $base-m-font-size;
        text-align: center;
        color: $black-color;
        font-weight: 600;

        &.selected {
          border-left-color: $primary-color;
          border-right-color: $primary-color;
        }

        .labelTooltipContainer {
          text-align: left;
          font-size: $base-font-size;
          line-height: 1.29;
          width: 100%;
          display: flex;
          align-items: center;

          label {
            padding-left: 1rem;
          }

          .tooltip {
            height: 1.5rem;
            width: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $secondary-color;
            font-family: $montserrat-font-family;
            font-size: $xs-font-size;
            font-weight: 600;
            line-height: 1.36;
            color: $white-color;

            &:hover {
              background-color: $primary-color;
            }
          }
        }

        .valueIcon {
          padding: 0.4rem 0;
          display: block;
          margin: 0 auto;
          height: 1.2rem;

          &.available {
            width: 1.7rem;
            background: url("../../../assets/images/checkmark.svg") center no-repeat;
          }

          &.notAvailable {
            width: 1.2rem;
            background: url("../../../assets/images/x.svg") center no-repeat;
          }
        }

        .price {
          margin-right: 0.5rem;
          color: $primary-color;
        }
      }
    }
  }

  .mShow {
    display: none;
  }
}

.variantTilesContainer {
  padding: 1rem 0;
  margin: 0 -0.5rem;
  display: flex;

  .variantTile {
    flex: 1;
    display: flex;
    justify-content: center;
    margin: 0 0.5rem;
    padding: 1rem;
    font-weight: 600;
    color: $black-color;
    border: 0.3rem solid $border-color;
    border-radius: 0.8rem;

    &.selected {
      border-color: $primary-color;
    }
  }
}

.variant {
  display: flex;
  flex-direction: column;
  align-items: center;

  .variantName {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $ml-font-size;
    margin-bottom: 1rem;
    text-align: center;
  }
}
.variantDuration {
  margin-top: 0.5rem;
  font-size: $small-font-size;
  line-height: 1.5;
  text-align: center;
}

.modalWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.6);
  padding: 4rem 2rem;
  z-index: 9999;
  display: none;

  &.isOpen {
    display: flex;
  }

  .modal {
    flex: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem;
    background: $white-color;
    border-radius: 1rem;

    .btnClose {
      position: absolute;
      display: flex;
      top: 2.5rem;
      right: 1.25rem;
      width: 1.2rem;
      height: 1.2rem;
      padding: 1rem;

      .iconClose {
        flex: 1;
        background: url('../../../assets/images/x.svg') no-repeat center;
        background-size: contain;
      }
    }

    .modalTitle {
      margin-bottom: 2rem;
      padding: 0 4rem 2rem 0;
      font-size: $m-font-size;
      border-bottom: 1px solid #333;
    }

    .modalContent {
      overflow: scroll;
      line-height: 1.8;
      font-weight: 400;
      white-space: pre-line;
    }
  }
}

@media only screen and (max-width: $breakpoint-medium) {
  .variantsTable {
    margin: 0;
    tbody {
      tr {
        td {
          border: none;

          .labelTooltipContainer {
            font-size: $small-base-font-size;
          }

          &:nth-child(2) {
            min-width: 14rem;
          }
        }
      }
    }
  }

  .variantTilesContainer {
    .variantTile {
      margin: 0 0.25rem;
      padding: 0.75rem;
    }
  }
  .variant {
    .variantName {
      font-size: $base-m-font-size;
    }
  }


  .mHide {
    display: none;
  }

  .mShow {
    display: block;
  }
}