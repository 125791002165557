@import "../../assets/styles/variables";

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $footer-background;
    flex-wrap: wrap;
    padding: 4.8rem 0 5.5rem;

    .footerContainer,
    .footerContainerMobile {
        display: flex;
        flex-direction: column;
        flex: 1;

        .footerRow {
            display: flex;
            flex: 1;
            justify-content: space-around;

            .footerColumn {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                    .logo {
                        width: 18rem;
                        height: 6rem;
                        background: url("../../assets/images/footer_logo.webp") left no-repeat;
                        background-size: contain;
                    }

                    .footerData {
                        display: flex;
                        flex-direction: column;

                        .columnTitle {
                            font-family: $montserrat-font-family;
                            font-size: $m-font-size;
                            font-weight: 600;
                            line-height: 1.56;
                            letter-spacing: 0.09rem;
                            color: $black-color;
                        }

                        .columnDetails {
                            font-family: $montserrat-font-family;
                            font-size: $base-s-font-size;
                            font-weight: 500;
                            line-height: 1.87;
                            letter-spacing: 0.075rem;
                            color: rgba($black-color, 0.7);
                            text-decoration: none;

                            &.link {
                                color: rgba($primary-color, 0.7);
                                text-decoration: underline;
                            }
                        }

                        .socialIconsWrapper {
                            display: flex;
                            justify-content: space-around;
                            align-items: stretch;

                            .socialLink {
                                width: 5rem;
                                height: 5rem;
                                margin: 0 0.25rem;
                                border-radius: 50%;
                                background-color: $white-color;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                box-shadow: 0 0 1.5rem 0 rgba($black-color, 0.1);

                                .icon {
                                    display: flex;
                                    height: 2rem;
                                    width: 2rem;
                                    justify-content: center;
                                    align-items: center;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: contain;

                                    &.facebook {
                                        background-image: url("../../assets/images/footer_fb_icon.webp");
                                    }

                                    &.instagram {
                                        background-image: url("../../assets/images/footer_ig_icon.webp");
                                    }

                                    &.linkedin {
                                        background-image: url("../../assets/images/footer_linkedin_icon.webp");
                                    }

                                    &.youtube {
                                        background-image: url("../../assets/images/footer_yt_icon.webp");
                                    }
                                }
                            }
                        }

                    &.customFooterData {
                        margin-bottom: 2rem;
                    }
                }
            }
        }
   }

    .footerContainerMobile {
        display: none;
    }
}


@media only screen and (max-width: $breakpoint-medium) {
    .footer {
        padding: 4rem 1.5rem 16rem;

        .footerContainer {
          display: none;
        }

        .footerContainerMobile {
            display: flex;

            .footerRow {
                display: flex;
                flex: 1;
                flex-direction: column;

                .footerColumn {
                    display: flex;
                    flex: 1;

                    .footerData {
                        justify-content: space-between;

                        .logo {
                            width: 18rem;
                            height: 6rem;
                            background: url("../../assets/images/logo_bw.png") left no-repeat;
                            background-size: contain;
                            margin: 3rem 0;
                        }

                        .columnDetails:last-of-type {
                            margin-bottom: 3.8rem;
                        }

                        .socialIconsWrapper {
                            margin-bottom: 2rem;
                        }

                        &.centered {
                          align-items: center;
                        }
                    }
                }
            }
        }
    }
}
