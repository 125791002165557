@import "variables";

.navigation-menu {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5rem;
    z-index: 1;
  }

  &:before {
    left: 0;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  }

  &:after {
    right: 0;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  }

  .wizard-steps-container {
    display: flex;
    padding: 2rem;
    margin: 0 0 3rem;
    overflow: auto;

    .wizard-step {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding: 0 1.2rem;
      white-space: nowrap;

      &:after {
        content: "";
        background: url('../images/arrow-right.png') center no-repeat;
        background-size: cover;
        display: block;
        position: absolute;
        right: -0.8rem;
        top: 0;
        width: 1.5rem;
        height: 1.8rem;
      }

      &:last-child {
        &:after {
          content: none;
        }
      }

      &.enabled {
          .label {
            color: $dark-text-color;
          }
      }

      &.enabled:not(.active):not(.head) {
          .label {
            cursor: pointer;
          }
      }

      &.enabled:not(.active) {
        .label {
            cursor: pointer;
        }
      }

        .label {
          list-style: none;
          font-family: $montserrat-font-family;
          font-size: $small-font-size;
          font-weight: 500;
          line-height: 1.25;
          letter-spacing: 0.06rem;
          color: $black-color;
        }

      &.active {
        .label {
          color: $primary-color;
          font-weight: bold;
        }
      }
    }
  }
}


@media (max-width: $breakpoint-medium) {
  .navigation-menu {
    margin: 0 -2rem;
    width: calc( 100% + 4rem);

    &:before,
    &:after {
      content: '';
    }
  }
}
